import axios from 'axios';
import {
    API_HEADER,
    PRODUCTS_API
} from '../../../constants/API';
import {
    GET_PRODUCT
} from '../types';


const getProduct = (id, title, shopify) => {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: GET_PRODUCT,
                loadingProduct: true,
                product: null
            });
            const { token } = getState().UserReducer;
            
            const productResponse = await axios.get(`${PRODUCTS_API}/${id}/${title}/${shopify}`, API_HEADER(token));
            dispatch({
                type: GET_PRODUCT,
                loadingProduct: false,
                product: productResponse.data.productToRespond,
                variants: productResponse.data.productVariants
            })
        } catch (e) {
            console.log(e)
            dispatch({
                type: GET_PRODUCT,
                loadingProduct: false,
                product: false
            })
        }
    }
}

export { getProduct };