import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import handleCatalogMetaTags from "../../helpers/handleCatalogMetaTags";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import connectScreen from "../../redux/connectScreen";
import Gallery from "../../components/Gallery";
import LoadingScreen from "../../components/LoadingScreen";
import Copy from "../../constants/Copy";
import getProductPrice from "../../helpers/getProductPrice";
import Banner from "../../components/Banner";
import Seperator from "../../components/Seperator";
import BuyGrid from "../../components/BuyGrid";
import {
  Container,
  ProductInfoContainer,
  ProductCondition,
  ProductTitle,
  ProductPrice,
  CompareProductPrice,
  PriceContainer,
  ProductDesc,
  LinkContainer,
  ErrorContainer,
  BuyButton,
  Label,
  ProductFeaturesContainer,
  ProductFeatures,
  ProductPageSizeGrid,
  ShippingText,
  Icon,
  PaymentOptions,
} from "./styles";
import affirmImage from "../../assets/images/affirm-black_logo-transparent_bg.png";
import afterPayImage from "../../assets/images/afterpay-badge-blackonmint72x15@3x.png";
import TextWithLogo from "../../components/TextWithLogo";
import Info from "../../assets/images/info.png";

const ProductPage = (props) => {
  const { variants } = props.product;
  const location = useLocation();
  const { pathname } = location;
  const stockXHandle = pathname.split("/")[2];
  const shopifyHandle = pathname.split("=")[1] || location.search.split("=")[1];
  const gtmDispatch = useGTMDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedOption, setSelectedOption] = useState("Best Price");
  const [shippingOptions, setShippingOptions] = useState(["Best Price"]);
  const [variantSize, setVariantSize] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    //console.log("PROPS", props);
    if (shopifyHandle) {
      //console.log("shopifyHandle", shopifyHandle);
      props.getProduct(null, stockXHandle, shopifyHandle);
    } else {
      //console.log("stockXHandle", stockXHandle);
      props.getProduct(null, stockXHandle, false);
    }
  }, []);

  useEffect(() => {
    if (
      !props.product ||
      !props.product.variants ||
      !props.product.product.sizes ||
      shopifyHandle
    ) {
      return; // Early return if required data is not available
    }

    const fbValue = getProductPrice(size, product);

    const updateMetaTags = () => {
      const metaTags = [
        { name: 'title', content: productTitle },
        { name: 'description', content: desc },
        { property: 'og:type', content: 'product' },
        { property: 'og:url', content: `https://shopwithsafe.com/product/${stockXHandle}` },
        { property: 'og:title', content: productTitle },
        { property: 'og:image', content: image[0] },
        { property: 'og:description', content: desc },
        { property: 'product:price:amount', content: fbValue.price },
        { property: 'product:price:currency', content: 'CAD' },
        { property: 'product:availability', content: 'in stock' },
        { property: 'product:condition', content: 'new' },
        { property: 'product:retailer_item_id', content: fbValue.id },
      ];

      metaTags.forEach((tag) => {
        const existingTag = document.querySelector(`meta[property="${tag.property}"]`);
        if (existingTag) {
          existingTag.setAttribute('content', tag.content);
        } else {
          const newTag = document.createElement('meta');
          newTag.setAttribute('property', tag.property);
          newTag.setAttribute('content', tag.content);
          document.head.appendChild(newTag);
        }
      });
    };
    handleCatalogMetaTags(product.brand.charAt(0).toUpperCase() + product.brand.slice(1)
    ,fbValue.id
    ,desc
    ,`https://shopwithsafe.com/product/${stockXHandle}`
    ,image[0]
    ,fbValue.price
    ,'in stock'
    );
    updateMetaTags();
    const stockxListings = props.product.product.Listings;
    const selectedListing = stockxListings.find(
      (listing) => listing.size === selectedSize
    );

    if (
      variantSize.includes(selectedSize) &&
      selectedListing &&
      selectedVariant
    ) {
      const selectedPrice = Number(selectedVariant.price);
      const listingPrice = Number(selectedListing.price);
      
      const hideBestPrice = selectedPrice < listingPrice;

      const shippingOptions = hideBestPrice
        ? ["Fast Ship"]
        : ["Best Price", "Fast Ship"];

      const selectedOption = hideBestPrice ? "Fast Ship" : "Best Price";

      setShippingOptions(shippingOptions);
      setSelectedOption(selectedOption);
    } else if (variantSize.includes(selectedSize) && !selectedListing) {
      setShippingOptions(["Fast Ship"]);
      setSelectedOption("Fast Ship");
    } else if (!variantSize.includes(selectedSize) && selectedListing) {
      setShippingOptions(["Best Price"]);
      setSelectedOption("Best Price");
    }

    if (window.fbq) {
      window.fbq("track", "ViewContent", {
        content_type: "product",
        contents: [
          {
            id: fbValue.id,
            quantity: 1,
            image_link: images[0],
            title: productTitle,
            price: fbValue.price,
            availability: "in stock",
            description: desc,
            link: `https://shopwithsafe.com/product/${stockXHandle}`,
          },
        ],
      });
    } else {
      console.log("no pixel");
    }
  }, [props.product, selectedSize, variantSize]);

  useEffect(() => {
    if (!props.product.loadingProduct) {
      gtmDispatch({
        event: "detail",
        detail: {
          actionField: { step: 1 },
          products: [
            {
              name: props.product.product.title,
              id: props.product.product.id,
              brand: props.product.product.brand,
              category: props.product.product.category,
              variant: props.product.product.size,
              quantity: 1,
            },
          ],
        },
      });
    }
  }, [props.product.loadingProduct]);

  useEffect(() => {
    if (shopifyHandle) return;
    const updateVariantSizeAndPrice = () => {
      if (props.product && props.product.variants) {
        getVariantPrice();
        getVariantsSizes();
      }
    };
    updateVariantSizeAndPrice();
  }, [props.product.variants, selectedSize]);

  useEffect(() => {
    console.log("VARIANTS", variants);
    if (!shopifyHandle) return;
    const selectShopifyVariant = () => {
      if (props.product && props.product.variants) {
        variants.find((variant) => {
          variant.selectedOptions.find((option) => {
            if (option.name === "Sizes") {
              if (option.value === selectedSize) {
                setSelectedVariant(variant);
              }
            }
          });
        });
      }
    };
    selectShopifyVariant();
  }, [variants, selectedSize]);

  useEffect(() => {
    if (!variants || !shopifyHandle) return;
    setSelectedSize(
      variants[0].selectedOptions.find((option) => option.name === "Sizes")
        .value
    );
  }, [variants]);

  const getVariantsSizes = () => {
    // men sizes
    const menSize = variants.map((variant) => variant.size.split(" / ")[0]);
    //filter sizes to remove duplicates and undefined.
    const filteredMenSize = [...new Set(menSize)].filter(
      (size) => size !== undefined
    );

    const sortedMenSize = filteredMenSize.sort((a, b) => a - b);
    
    // women sizes
    const womenSize = variants.map((variant) => variant.size.split(" / ")[1]);
    const filteredWomenSize = [...new Set(womenSize)].filter(
      (size) => size !== undefined
    );

    const sortedWomenSize = filteredWomenSize.sort((a, b) => {
      const aSize = a.split("W")[0];
      const bSize = b.split("W")[0];
      return aSize - bSize;
    });

    // kids sizes
    const kidsSize = variants.map(
      (variant) => `${variant.size.split(" / ")[0]}Y`
    );
    const filteredKidsSize = [...new Set(kidsSize)].filter(
      (size) => size !== undefined
    );
    const sortedKidsSize = filteredKidsSize.sort((a, b) => a - b);
    if (props.product.product.gender === "men") {
      //onsole.log("sortedMenSize", sortedMenSize);
      setVariantSize(sortedMenSize);
    } else if (props.product.product.gender === "women") {
      setVariantSize(sortedWomenSize);
    } else if (props.product.product.gender === "child") {
      setVariantSize(sortedKidsSize);
    }
  };

  const getVariantPrice = () => {
    variants.forEach((variant) => {
      if (selectedSize && props.product.product.gender === "child") {
        let selectedSizeFormatted = selectedSize.split("Y")[0];
        let variantSizeFormatted = variant.size.split(" / ")[0];
        if (variantSizeFormatted === selectedSizeFormatted) {
          setSelectedVariant(variant);
        }
      }
      if (selectedSize && props.product.product.gender === "women") {
        let variantSizeFormatted = variant.size.split(" / ")[1];
        if (variantSizeFormatted === selectedSize) {
          setSelectedVariant(variant);
        }
      } else if (selectedSize && props.product.product.gender === "men") {
        let variantSizeFormatted = variant.size.split(" / ")[0];
        if (variantSizeFormatted === selectedSize) {
          setSelectedVariant(variant);
        }
      }
    });
  };

  if (props.product.loadingProduct) {
    return <LoadingScreen />;
  }
  if (!props.product.product) {
    return <ErrorContainer>{Copy.ProductLoadingErrorMessage}</ErrorContainer>;
  }
  const product = props.product.product;

  const { title: productTitle, desc, sizes, images, price } = product;
  const image = shopifyHandle ? images : selectedImage || images[0];

  const getSizesWithListings = () => {
    const filteredSizes = [];
    const amountOfSizes = sizes.length;
    for (let i = 0; i < amountOfSizes; i++) {
      const sizeToFilter = sizes[i];
      const priceObject = getProductPrice(sizeToFilter, product);
      if (priceObject && priceObject !== "N/A") {
        filteredSizes.push(sizeToFilter);
      }
    }
    // join filtered sizes with variantSizes in a array and remove duplicates.
    const filteredSizesWithVariant = [
      ...new Set([...filteredSizes, ...variantSize]),
    ];
    const sortedSizes = filteredSizesWithVariant.sort((a, b) => a - b);
    if (selectedSize && !sortedSizes.includes(selectedSize)) {
      setSelectedSize(sortedSizes[0]);
    }
    return sortedSizes;
  };

  const size =
    shopifyHandle && variants.length > 0
      ? variants[0].selectedOptions.find((option) => option.name === "Sizes")
          .value
      : selectedSize || getSizesWithListings()[0];
  const sizesWithListings = shopifyHandle ? sizes : getSizesWithListings();

  // Check if the page has already been reloaded
  const isPageReloaded = localStorage.getItem("isPageReloaded");

  if (!sizesWithListings.length || !size) {
    if (shopifyHandle) return;
    if (!isPageReloaded) {
      // Set the flag to indicate that the page has been reloaded
      localStorage.setItem("isPageReloaded", "true");
      window.location.reload();
    } else {
      // Clear the flag to allow subsequent reloads in the future
      localStorage.removeItem("isPageReloaded");
    }
  }

  const priceData = !shopifyHandle && getProductPrice(size, product);

  const handleGTMEvent = (product) => {
    gtmDispatch({
      event: "checkout",
      checkout: {
        actionField: { step: 1 },
        products: [
          {
            name: product.title,
            id: product.id,
            brand: product.brand,
            category: product.category,
            variant: product.size,
            quantity: 1,
          },
        ],
      },
    });
  };

  const finalPrice =
  (variants.length > 0 && selectedOption === "Fast Ship" && !shopifyHandle
    ? selectedVariant.price
    : priceData.price) ||
  (variants.length > 0 && shopifyHandle && selectedVariant
    ? selectedVariant.price
    : null);

  return (
    <>
    {/* {handleMetaTags(product.brand.charAt(0).toUpperCase() + product.brand.slice(1))} */}
    <Container>
      <Banner text={Copy.DUTY_BANNER} />
      <Gallery
        images={images}
        onSelectImage={(image) => setSelectedImage(image)}
        selectedImage={image}
      />
      <ProductInfoContainer>
        <div
          style={{
            display: "flex",

            alignItems: "center",
          }}
        >
          <ProductCondition>
            100% Authentic and Brand New Guaranteed
          </ProductCondition>
          <Icon src={Info} alt="info" />
        </div>
        <ProductTitle>{productTitle}</ProductTitle>
        <PriceContainer>
          <ProductPrice>
            ${finalPrice}
          </ProductPrice>
          {priceData.compareAt && (
            <CompareProductPrice> ${priceData.compareAt}</CompareProductPrice>
          )}
        </PriceContainer>
        <Label>Size</Label>
        <ProductPageSizeGrid
          containerStyle={{
            width: "100%",
            marginLeft: "0px",
            marginRight: "0px",
          }}
          sizes={sizesWithListings}
          onSizeClick={(size) => {
            setSelectedSize(size);
          }}
          selectedSize={shopifyHandle ? selectedSize : size}
          xs={3}
          sm={2}
          md={2}
          lg={2}
          xl={2}
        />
        <Label>Shipping</Label>
        <BuyGrid
          onOptionClick={(option) => setSelectedOption(option)}
          selectedOption={selectedOption}
          buyButtons={shippingOptions}
        />
        {selectedOption === "Fast Ship" ? (
          <LinkContainer
            onClick={() => handleGTMEvent(product)}
            to={{
              pathname: "/buy",
              state: {
                size,
                stockXHandle,
                productId: product.id,
                selectedVariant,
              },
            }}
          >
            <BuyButton>Buy</BuyButton>
          </LinkContainer>
        ) : (
          <LinkContainer
            onClick={() => handleGTMEvent(product)}
            to={{
              pathname: "/buy",
              state: shopifyHandle
                ? {
                    size: selectedSize,
                    shopifyHandle,
                    stockXHandle,
                    productId: product.id,
                    selectedVariant,
                  }
                : { size, stockXHandle, productId: product.id, variants },
            }}
          >
            <BuyButton>Buy</BuyButton>
          </LinkContainer>
        )}
        {/* or 4 payments of $40 https://www.afterpay.com/en-CA/terms-of-service */}
        <PaymentOptions>
          <TextWithLogo logoUrl={afterPayImage} url={"https://www.afterpay.com/en-CA/terms-of-service"} text={`Pay it in 4`} />
          <TextWithLogo logoUrl={affirmImage} text={"Financing"} />
        </PaymentOptions>
        {selectedOption === "Fast Ship" ? (
          <ShippingText>{Copy.fastShippingText}</ShippingText>
        ) : (
          <ShippingText>{Copy.bestPriceText}</ShippingText>
        )}
        <Seperator />
        <ProductFeaturesContainer>
          <ProductFeatures>Condition: NEW</ProductFeatures>
          <ProductFeatures>Style Id: {product.sku}</ProductFeatures>
          <ProductFeatures>Color: {product.color}</ProductFeatures>
        </ProductFeaturesContainer>

        <Seperator />
        <ProductDesc dangerouslySetInnerHTML={{ __html: desc }} />
      </ProductInfoContainer>
    </Container>
    </>
  );
};

export default connectScreen(ProductPage);
